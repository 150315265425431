/* eslint-disable react/no-danger */
import React from 'react'

// parse out the extra text node and return if it exists
const Head = ({ data }) => {
  if (!data) return null
  return <div className="headline" dangerouslySetInnerHTML={{ __html: data.childMarkdownRemark.html }} />
}

const SubHead = ({ data }) => {
  if (!data) return null
  return <div className="subHeadline" dangerouslySetInnerHTML={{ __html: data.childMarkdownRemark.html }} />
}

function Headline({ data }) {
  const { headline, subHeadline } = data

  return (
    <>
      <Head data={headline} />
      <SubHead data={subHeadline} />
    </>
  )
}

export default Headline
