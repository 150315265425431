import React from 'react'
import styled from '@emotion/styled'
import { GatsbyImage as Img } from 'gatsby-plugin-image'

const Quote = styled.blockquote``

const Author = styled.figcaption``

const Testimonial = ({ quote, author, image, authorProfile }) => (
  <div className="testimonial">
    {quote && (
      <figure>
        <Quote className="quote">
          {image && <Img image={image.gatsbyImageData} alt={image.title || 'Untitled Image'} />}
          <div className="description">
            <div className="quote-content" dangerouslySetInnerHTML={{ __html: quote }} />
            <Author className="author">{author}</Author>
            {authorProfile && <span>{authorProfile}</span>}
          </div>
        </Quote>
      </figure>
    )}
  </div>
)

export default Testimonial
