import React from 'react'
import Testimonial from 'components/Testimonial'

function TestimonialQuote({ data }) {
  // console.log('🗯 testimonial quote data: ', data)
  const { author, quoteBlock, image, authorProfile } = data
  const {
    childMarkdownRemark: { html },
  } = quoteBlock

  return (
    <Testimonial className="testimonial" quote={html} author={author} image={image} authorProfile={authorProfile} />
  )
}

export default TestimonialQuote
