import React from 'react'
import { GatsbyImage as Img } from 'gatsby-plugin-image'

function ImageWithCaption({ data }) {
  const { image, caption } = data
  return (
    <figure className="image-with-caption">
      {image && <Img image={image.gatsbyImageData} alt={image.description || image.title || 'Untitled Image'} />}
      {caption && <figcaption>{caption}</figcaption>}
    </figure>
  )
}

export default ImageWithCaption
