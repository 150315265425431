import React from 'react'
import CTA from 'components/content-modules/CTA'
import Headline from 'components/content-modules/Headline'
import HeroImage from 'components/content-modules/HeroImage'
import Container from 'components/common/Container'

function Header({ data }) {
  // console.log('🚀 data', data)
  const { image, cta, headline, subHeadline } = data

  return (
    <Container className="heroSection">
      <HeroImage data={image} />
      <div className="hero-content">
        <Headline data={headline} />
        <p className="subHeadline">{subHeadline}</p>
        <CTA data={cta} />
      </div>
    </Container>
  )
}

export default Header
