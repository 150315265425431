import React from 'react'
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import Container from 'components/common/Container'

function HeroImage({ data }) {
  const { heroImage } = data
  return (
    <Container type="wrapper" className="hero-image">
      <Img image={heroImage.gatsbyImageData} alt={heroImage.description || heroImage.title || 'Untitled Image'} />
    </Container>
  )
}

export default HeroImage
